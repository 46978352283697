<div class="transition transition__container">
    @if (type === 'WELCOME') {
        <hdmui-avatar
            [fullName]="user?.fullName || ''"
            [matTooltip]="user?.email || ''"
            [avatarUrl]="user?.avatarUrl || undefined"
            matTooltipPosition="above"
            class="hdmui-avatar-large"
        ></hdmui-avatar>

        <h2 id="transition__title">
            {{ 'DC.TRANSITION.WELCOME.TITLE' | translate: { userName: user?.givenName } }}
        </h2>
    }

    <p id="transition__text">{{ transitionText.text }}</p>

    @if (transitionText.list && transitionText.list.length > 0) {
        <ul id="transition__list">
            @for (point of transitionText.list; track point) {
                <li>{{ point }}</li>
            }
        </ul>
    }

    <p id="transition__counter">
        {{ 'DC.TRANSITION.' + type + '.COUNTER_TEXT' | translate }}
        {{ timer$ | async }}{{ 'DC.TRANSITION.TIME_UNIT' | translate }}
    </p>

    <button class="transition__button" (click)="onButtonClick()" mat-button>
        {{ 'DC.TRANSITION.' + type + '.BUTTON' | translate }}
    </button>
</div>
