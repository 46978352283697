import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';

import { CCAuthService } from '@heidelberg/control-center-frontend-integration/auth';

import { ROUTE_PATH } from '@data-terminal/shared-models';
import { CloudSessionService } from '@data-terminal/data-access';
import { isToday } from '@data-terminal/utils';

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class TransitionService {
    constructor(
        private readonly router: Router,
        private readonly cloudSessionService: CloudSessionService,
        private readonly ccAuthService: CCAuthService
    ) {}

    public get shouldDisplayWelcomeScreen$(): Observable<boolean> {
        const lastSessionUpdateDate = this.cloudSessionService.getSessionData()?.updatedAt;

        if (!lastSessionUpdateDate) {
            return of(true);
        }

        return of(!isToday(new Date(lastSessionUpdateDate)));
    }

    public navigateToWelcomePage(): void {
        const orgId = this.ccAuthService.getCurrentMembership()?.organizationId;
        this.router.navigateByUrl(`${orgId}/${ROUTE_PATH.welcome}`);
    }

    public navigateToFarewellPage(): void {
        const orgId = this.ccAuthService.getCurrentMembership()?.organizationId;
        this.router.navigateByUrl(`${orgId}/${ROUTE_PATH.farewell}`);
    }
}
